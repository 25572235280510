import WebService from "../../util/webService";
import constant from "../../util/constant";
import { setLoader } from "./loaderActions";
import { getLocalData } from "../../util/helper";
import { getInstance } from "../../lib/globalTracker";

export const GET_FAVORITE = "GET_FAVORITE";
export const ADD_TO_FAVORITE = "ADD_TO_FAVORITE";
export const DELETE_FROM_FAVORITE = "DELETE_FROM_FAVORITE";

//ADD_TO_FAVORITE
export const addToFavorite = (userData, productId) => {
  return async (dispatch) => {
    const currentLanguageCode = JSON.parse(localStorage.getItem("redux_localstorage_simple")).multilanguage.currentLanguageCode || "ko";

    const globalTracker = getInstance(currentLanguageCode);
    if (globalTracker) {
      globalTracker.onEvent({
        event_id: "dtl_coll_clk", // Event ID
        event_type: "click", // Event type
        event_name: "button_click", // Event name
        params: {
          // Event parameter information
          main_prod_id: productId,
          opn_type: "do",
          favorites_id: "",
        },
      });
    }

    dispatch(setLoader(true));
    let param = { productId: productId, userId: userData?.id };
    // if(isBookMark===false){
    let action = constant.ACTION.AUTH + constant.ACTION.FAVORITE + constant.ACTION.PRODUCT; // ** /auth/favorite/product

    try {
      await WebService.post(action, param);
      dispatch(getFavorite(userData));
      // ** dispatch(setLoader(false));
    } catch (error) {
      // ** dispatch(setLoader(false));
      dispatch(setLoader(false));
    }
  };
};

//DELETE_FROM_FAVORITE
export const deleteFromFavorite = (userData, productId) => {
  return async (dispatch) => {
    const currentLanguageCode = JSON.parse(localStorage.getItem("redux_localstorage_simple")).multilanguage.currentLanguageCode || "ko";

    const globalTracker = getInstance(currentLanguageCode);
    if (globalTracker) {
      globalTracker.onEvent({
        event_id: "dtl_coll_clk", // Event ID
        event_type: "click", // Event type
        event_name: "button_click", // Event name
        params: {
          // Event parameter information
          main_prod_id: productId,
          opn_type: "cancel",
          favorites_id: "",
        },
      });
    }

    dispatch(setLoader(true));
    let action = constant.ACTION.AUTH + constant.ACTION.FAVORITE + constant.ACTION.PRODUCT + `${productId}?userId=${userData?.id}`; // ** /auth/favorite/product
    try {
      await WebService.delete(action);

      dispatch({
        type: DELETE_FROM_FAVORITE,
        payload: productId,
      });

      dispatch(getFavorite(userData));
    } catch (error) {
      console.log("핸들오류:", error);
      dispatch(setLoader(false));
    }
  };
};

//GET_FAVORITE
export const getFavorite = (userData, count = "") => {
  return async (dispatch) => {
    let action =
      constant.ACTION.AUTH +
      constant.ACTION.FAVORITE +
      "list" +
      `?count=${count}` +
      `&userId=${userData?.id}` +
      "&lang=" +
      JSON.parse(getLocalData("redux_localstorage_simple")).multilanguage.currentLanguageCode; // ** /auth/customer/profile
    try {
      let response = await WebService.get(action);
      if (response) {
        dispatch({
          type: GET_FAVORITE,
          payload: response,
        });
      }
    } catch (error) {
      dispatch(setLoader(false));
    } finally {
      dispatch(setLoader(false));
    }
  };
};
// test
export const getIsFavorite = (productId) => {
  return async (dispatch) => {
    let action = constant.ACTION.AUTH + constant.ACTION.FAVORITE + "user" + "/is_favorited" + `/${productId}`;
    try {
      let response = await WebService.get(action);
      if (response) {
        return response.data;
      }
    } catch (error) {
      dispatch(setLoader(false));
    } finally {
      dispatch(setLoader(false));
    }
  };
};
