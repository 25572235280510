import React, { useState, useEffect } from "react"; // ** useRef
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom"; // ** useLocation
import { useAlert } from "../../../../alert";
import WithAuth from "../../../../components/WithAuth";
import constant from "../../../../util/constant";
import WebService from "../../../../util/webService";
import LanguageText from "../../../../components/languageText/LanguageText";
const MyAccountMenu = ({ pathname, strings, userData }) => {
  /* ==============================
	* SweetAlert 셋팅.
	================================= */
  const { showAlert, showConfirm } = useAlert();
  const commingSoon = () => {
    showAlert({
      message: strings["Coming soon."],
      confirm: strings["Accept"],
    });
    return;
  };

  /* ==============================
	* Select authentication method.
	================================= */
  const location = useLocation();
  const membType = location.state?.membType || "CUSTOMER";
  const [uriMemTypeValue, setUriMemTypeValue] = useState(membType);

  /* ==============================
	* Only for Enterprise member
	================================= */
  const pathSegments = location.pathname.split("/");
  const tab = pathSegments[1];

  /* ==============================
	* GET : User Profile
	================================= */
  const [storeCode, setStoreCode] = useState("");
  useEffect(() => {
    /*
		if (!userData) {
		// */
    getProfile();
    /*
		} else {
			setStoreCode(userData?.storeCode);
		}
		// */
  }, [userData]);
  const getProfile = async () => {
    let action = constant.ACTION.AUTH + constant.ACTION.CUSTOMER + constant.ACTION.PROFILE; // ** /auth/customer/profile
    try {
      let response = await WebService.get(action);
      if (response) {
        // ** console.log( "MyPageMenu : getProfile response : ", response );
        setStoreCode(response.storeCode);
      }
    } catch (error) {
      console.error(error);
    }
  };

  /* ==============================
	* GET : Store Profile
	================================= */
  const [storeCodeIsDefault, setStoreCodeIsDefault] = useState("");
  const [storeStatuseIsApprove, setStoreStatuseIsApprove] = useState("");
  const getStore = async (storeCode) => {
    // ** console.log( "storeCode : ", storeCode );
    if (storeCode && storeCode === "DEFAULT") {
      setStoreCodeIsDefault(true);
    } else {
      setStoreCodeIsDefault(false);
    }

    /*
		if (!userData) {
		// */
    try {
      let action = constant.ACTION.STORE + storeCode;
      let response = await WebService.get(action);
      if (response) {
        if (response?.status && response?.status === "APPROVE" && response?.code !== "DEFAULT") {
          setStoreStatuseIsApprove(true);
        } else {
          setStoreStatuseIsApprove(false);
        }
      }
    } catch (error) {
      console.log(error);
    }

    /*
		} else {
			console.log( "#2" );
			console.log( "#2 userData?.status : ", userData?.status );
			console.log( "#2 userData?.storeCode : ", userData?.storeCode );
			let action = constant.ACTION.STORE + storeCode;
			let response = await WebService.get( action );
			if ( response ) {
				console.log( "response : ", response );
			}

			if ( userData?.status && userData?.status === "APPROVE" && userData?.storeCode !== "DEFAULT" ) {
				console.log( "#2-1" );
				setStoreStatuseIsApprove( true );
			} else {
				console.log( "#2-2" );
				setStoreStatuseIsApprove( false );
			}
		}
		// */
  };

  const [isMenu, setIsMenu] = useState(false);
  useEffect(() => {
    if (storeCode && storeCode !== "") {
      // ** console.log( "storeCode : ", storeCode );
      getStore(storeCode);
      /*
			console.group( "가릴메뉴 필터링" );
			console.log( "storeCodeIsDefault : ", storeCodeIsDefault );
			console.log( "storeStatuseIsApprove : ", storeStatuseIsApprove );
			console.log( "* APPROVE, widenety상점 계정" );
			console.log( "widenety1 [ storeCodeIsDefault ] : false" );
			console.log( "widenety1 [ storeStatuseIsApprove ] : true" );
			console.log( "* WATING 계정" );
			console.log( "ccs005 [ storeCodeIsDefault ] : false" );
			console.log( "ccs005 [ storeStatuseIsApprove ] : false" );
			console.log( "* 방금 가입 계정" );
			console.log( "widenety [ storeCodeIsDefault ] : true" );
			console.log( "widenety [ storeStatuseIsApprove ] : false" );
			console.groupEnd();
			// */

      if (!storeCodeIsDefault && storeStatuseIsApprove) {
        setIsMenu(true);
      } else {
        setIsMenu(false);
      }
    }
  }, [storeCode, storeCodeIsDefault, storeStatuseIsApprove]);

  return (
    <div className={`inner ENTERPRISE`}>
      <div className="mmUnitWrap enterprise">
        <div className="tabPkg">
          <div className="tabNavi">
            <ul>
              <li className={tab === "my" ? "isActive" : ""}>
                <Link to="/my/account-enterprise">
                  <span>{strings["Purchase management"]}</span>
                </Link>
              </li>
              {storeStatuseIsApprove ? (
                <li className={tab === "my-seller" ? "isActive" : ""}>
                  <Link to="/my-seller/orderList">
                    <span>{strings["Sales management"]}</span>
                  </Link>
                </li>
              ) : (
                <li className={tab === "my-seller" ? "isActive" : ""}>
                  <Link to="/my-seller/store">
                    <span>{strings["Sales management"]}</span>
                  </Link>
                </li>
              )}
            </ul>
          </div>
          {isMenu ? (
            <>
              <div className="tabBody">
                {tab === "my" && (
                  <div className="Buyer active">
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My Information"]}</h3>
                      <ul className="mmList1">
                        <li
                          className={
                            pathname === "/my/account-enterprise" || pathname === "/my/account-enterprise" ? "on" : ""
                          }
                        >
                          <Link to={{ pathname: "/my/account-enterprise", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Private Information"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Payment method management"]}</span>
                          </button>
                        </li>
                        <li className={pathname === "/my/address" || pathname === "/my/address" ? "on" : ""}>
                          <Link to={{ pathname: "/my/address", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Shipping destination management"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Agree to receive marketing information"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["SNS connection settings"]}</span>
                          </button>
                        </li>
                        <li className={pathname === "/my/withdraw" ? "on" : ""}>
                          <Link to={{ pathname: "/my/withdraw", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Withdraw"]}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My order"]}</h3>
                      <ul className="mmList1">
                        <li className={pathname === "/my/cart" ? "on" : ""}>
                          <Link to={{ pathname: "/my/cart", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Cart"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my/estimateManagement" ? "on" : ""}>
                          <Link to={{ pathname: "/my/estimateManagement", state: { membType: uriMemTypeValue } }}>
                            <LanguageText
                              languageTextMapping={{
                                en: "Estimate management",
                                ko: "견적관리",
                                zh: "估价管理",
                                jp: "견적관리",
                              }}
                              Tag="span"
                            />
                          </Link>
                        </li>
                        {/* <li className={ pathname === "/finish-checkout" || pathname === "/finish-checkout" ? "on":"" }><Link to={{ pathname:"/finish-checkout", state:{ membType:uriMemTypeValue } }}><span>체크아웃</span></Link></li> */}
                        <li className={pathname === "/my/recent-order" || pathname === "/my/order-details" ? "on" : ""}>
                          <Link to={{ pathname: "/my/recent-order", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Order Delivery inquiry"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Cancellation return exchange refund"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Inquiry Output of Receipt"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My benefit"]}</h3>
                      <ul className="mmList1">
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["discount coupon"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Biz membership"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["KBiz Cash"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["mileage"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My activities"]}</h3>
                      <ul className="mmList1">
                        <li className={`${pathname === "/my/qnaWrite" ? "on" : ""} `}>
                          <Link to={{ pathname: "/my/qnaWrite", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["To inquire"]}</span>
                          </Link>
                        </li>
                        <li className={`${pathname === "/my/qnaList" || pathname === "/my/qnaView" ? "on" : ""}`}>
                          <Link to={{ pathname: "/my/qnaList", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Check inquiry details"]}</span>
                          </Link>
                        </li>
                        <li className={`${pathname === "/my/chatList" || pathname === "/my/chatList" ? "on": ""}`}><Link to={{ pathname:"/my/chatList", state:{ membType:uriMemTypeValue } }}><span>{strings["1:1 chat history"]}</span></Link></li>

                        {/* <li className={`later ${pathname === "/***"  ? "on":""} `}><button type="button" onClick={commingSoon}><span>{strings["Review Management"]}</span></button></li> */}
                        <li className={`${pathname === "/my/review" ? "on" : ""} `}>
                          <Link to={{ pathname: "/my/review", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Review Management"]}</span>
                          </Link>
                        </li>
                        <li className={`${pathname === "/my/favorite" ? "on" : ""} `}>
                          <Link to={{ pathname: "/my/favorite", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Jjim list"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Event participation status"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {tab === "my-seller" && (
                  <div className="Seller active">
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["Order Delivery"]}</h3>
                      <ul className="mmList1">
                        <li className={pathname === "/my-seller/orderList" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/orderList", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Order list"]}</span>
                          </Link>
                        </li>
                        <li className={`later pathname === "/***" ? "on":""`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["cancellation refund"]}</span>
                          </button>
                        </li>
                        <li className={pathname === "/my-seller/settlement" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/settlement", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["strSettlement managementing"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my-seller/quoteList" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/quoteList", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Quote List"]}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["Store management"]}</h3>
                      <ul className="mmList1">
                        <li className={pathname === "/my-seller/store" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/store", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Basic Information"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my-seller/delivery" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/delivery", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Delivery Settings"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my-seller/library" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/library", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Image Library"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my-seller/auth" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/auth", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["certification documents"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my-seller/asset" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/asset", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Intellectual property documents"]}</span>
                          </Link>
                        </li>
                        {window.location.hostname === "localhost" ? (
                          <li className={pathname === "/my-seller/smartContents" ? "on" : ""}>
                            <Link to={{ pathname: "/my-seller/smartContents", state: { membType: uriMemTypeValue } }}>
                              <span>스마트컨텐츠</span>
                            </Link>
                          </li>
                        ) : (
                          <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                            <button type="button" onClick={commingSoon}>
                              <span>스마트컨텐츠</span>
                            </button>
                          </li>
                        )}
                        <li className={pathname === "/my-seller/csList" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/csList", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Customer Inquiry"]}</span>
                          </Link>
                        </li>
                        <li className={ pathname === "/my-seller/chatList" ? "on":"" }><Link to={{ pathname:"/my-seller/chatList", state:{ membType:uriMemTypeValue } }}><span>{strings["Chat Inquiry"]}</span></Link></li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["Product management"]}</h3>
                      <ul className="mmList1">
                        <li className={pathname === "/my-seller/productList" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/productList", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Product list"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my-seller/productRegister" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/productRegister", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Product registration"]}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["GQ Management"]}</h3>
                      <ul className="mmList1">
                        <li className={`later pathname === "/***" ? "on":""`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["GQ Status List"]}</span>
                          </button>
                        </li>
                        <li className={`later pathname === "/***" ? "on":""`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Smart GQ Quotation Management"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["Marketing Management"]}</h3>
                      <ul className="mmList1">
                        <li className={`later pathname === "/***" ? "on":""`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Ad marketing"]}</span>
                          </button>
                        </li>
                        <li className={`later pathname === "/***" ? "on":""`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Membership"]}</span>
                          </button>
                        </li>
                        <li className={`later pathname === "/***" ? "on":""`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Special exhibition hot deal"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <Link
                        className={`mmTit1 single ${pathname === "/my-seller/ipCenter" ? "on" : ""}`}
                        to={{ pathname: "/my-seller/ipCenter", state: { membType: uriMemTypeValue } }}
                      >
                        <span>IP Safety Center</span>
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="tabBody">
                {tab === "my" && (
                  <div className="Buyer active">
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My Information"]}</h3>
                      <ul className="mmList1">
                        <li
                          className={
                            pathname === "/my/account-enterprise" || pathname === "/my/account-enterprise" ? "on" : ""
                          }
                        >
                          <Link to={{ pathname: "/my/account-enterprise", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Private Information"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Payment method management"]}</span>
                          </button>
                        </li>
                        <li className={pathname === "/my/address" || pathname === "/my/address" ? "on" : ""}>
                          <Link to={{ pathname: "/my/address", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Shipping destination management"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Agree to receive marketing information"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["SNS connection settings"]}</span>
                          </button>
                        </li>
                        <li className={pathname === "/my/withdraw" ? "on" : ""}>
                          <Link to={{ pathname: "/my/withdraw", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Withdraw"]}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My order"]}</h3>
                      <ul className="mmList1">
                        <li className={pathname === "/my/cart" ? "on" : ""}>
                          <Link to={{ pathname: "/my/cart", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Cart"]}</span>
                          </Link>
                        </li>
                        <li className={pathname === "/my/estimateManagement" ? "on" : ""}>
                          <Link to={{ pathname: "/my/estimateManagement", state: { membType: uriMemTypeValue } }}>
                            <LanguageText
                              languageTextMapping={{
                                en: "Estimate management",
                                ko: "견적관리",
                                zh: "估价管理",
                                jp: "견적관리",
                              }}
                              Tag="span"
                            />
                          </Link>
                        </li>

                        <li className={pathname === "/my/recent-order" || pathname === "/my/order-details" ? "on" : ""}>
                          <Link to={{ pathname: "/my/recent-order", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Order Delivery inquiry"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Cancellation return exchange refund"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Inquiry Output of Receipt"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My benefit"]}</h3>
                      <ul className="mmList1">
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["discount coupon"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Biz membership"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["KBiz Cash"]}</span>
                          </button>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""}`}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["mileage"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["My activities"]}</h3>
                      <ul className="mmList1">
                        <li className={`${pathname === "/my/qnaWrite" ? "on" : ""} `}>
                          <Link to={{ pathname: "/my/qnaWrite", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["To inquire"]}</span>
                          </Link>
                        </li>
                        <li className={`${pathname === "/my/qnaList" || pathname === "/my/qnaView" ? "on" : ""}`}>
                          <Link to={{ pathname: "/my/qnaList", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Check inquiry details"]}</span>
                          </Link>
                        </li>
                        <li className={`${pathname === "/my/chatList" || pathname === "/my/chatList" ? "on" : ""}`}>
                          <Link to={{ pathname: "/my/chatList", state: { membType: uriMemTypeValue } }}>
                            <span>1:1 채팅 내역 확인</span>
                          </Link>
                        </li>
                        {/* <li className={`later ${pathname === "/***"  ? "on":""} `}><button type="button" onClick={commingSoon}><span>{strings["Review Management"]}</span></button></li> */}
                        <li className={`${pathname === "/my/review" ? "on" : ""} `}>
                          <Link to={{ pathname: "/my/review", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Review Management"]}</span>
                          </Link>
                        </li>
                        <li className={`${pathname === "/my/favorite" ? "on" : ""} `}>
                          <Link to={{ pathname: "/my/favorite", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Jjim list"]}</span>
                          </Link>
                        </li>
                        <li className={`later ${pathname === "/***" ? "on" : ""} `}>
                          <button type="button" onClick={commingSoon}>
                            <span>{strings["Event participation status"]}</span>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {tab === "my-seller" && (
                  <div className="Seller active">
                    <div className="mmUnit">
                      <h3 className="mmTit1">{strings["Store management"]}</h3>
                      <ul className="mmList1">
                        <li className={pathname === "/my-seller/store" ? "on" : ""}>
                          <Link to={{ pathname: "/my-seller/store", state: { membType: uriMemTypeValue } }}>
                            <span>{strings["Basic Information"]}</span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="tabCommon">
            <ul>
              <li className="later">
                <button type="button" onClick={commingSoon}>
                  <i className="ri-draft-line ico"></i>
                  <span className="txt">{strings["GQ Market Inquiry Details"]}</span>
                </button>
              </li>
              <li>
                <Link to={{ pathname: "/cs-main" }}>
                  <i className="ri-feedback-line ico"></i>
                  <span className="txt">{strings["Customer Service"]}</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    currentLanguageCode: state.multilanguage.currentLanguageCode,
    defaultStore: state.merchantData.defaultStore,
    isLoading: state.loading.isLoading,
    userData: state.userData.userData,
  };
};
export default connect(mapStateToProps)(multilanguage(WithAuth(MyAccountMenu)));
