import { merge } from 'lodash';

const domain = window.location.hostname;

let config = require('./config_default.json');

if (['test-kr.sourcingroot.kr', 'sourcingroot.co.kr', 'www.sourcingroot.co.kr'].includes(domain)) {
  const krConfig = require('./config_kr.json');
  config = merge({}, config, krConfig);
} else if (['test-jp.sourcingroot.kr', 'sourcingroot.jp', 'www.sourcingroot.jp'].includes(domain)) {
  const jpConfig = require('./config_jp.json');
  config = merge({}, config, jpConfig);
} else if (['test-us.sourcingroot.kr', 'sourcingroot.com', 'www.sourcingroot.com'].includes(domain)) {
  const usConfig = require('./config_us.json');
  config = merge({}, config, usConfig);
}


export default config;
