import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const WithAuth = (Component) => {
  const AuthCheck = (props) => {
    const token = localStorage.getItem("token");
    const isAuthenticated = useSelector((state) => state?.userData?.userData?.id);

    if (!isAuthenticated && !token) {
      return <Redirect to="/login" />;
    }

    return <Component {...props} />;
  };

  return AuthCheck;
};

export default WithAuth;
