import React, { useEffect } from "react";
import MyPageSummary from "../pages/other/MyPage/frame/MyPageSummary";
import MyPageMenu from "../pages/other/MyPage/frame/MyPageMenu";
import WithAuth from "../components/WithAuth";
import { useLocation, useHistory } from "react-router-dom";
import LanguageText from "../components/languageText/LanguageText";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../redux/actions/userAction";
import { setLocalData } from "../util/helper";
import { resetStore } from "../redux/actions/storeAction";
import { DeleteFCMToken } from "../util/FCMTokenManager";

const MyPageLayout = ({ children }) => {
  const { pathname } = useLocation();
  const userData = useSelector((state) => state.userData.userData);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!userData.id || !token || !userData) {
      // 강제 로그아웃 시키기
      dispatch(setUser(null));
      setLocalData("token", "");
      dispatch(resetStore());
      DeleteFCMToken();
      history.push("/login?error=1");
    }
  }, [token, userData, userData.id]);

  return (
    <div className="myArea">
      <div className="myPkg">
        <div className="mySumm">
          <MyPageSummary />
        </div>
        <div className="myMenu">
          <MyPageMenu pathname={pathname} />
        </div>
        <div className="myContents">{children}</div>
      </div>
    </div>
  );
};
export default WithAuth(MyPageLayout);
