import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const LanguageText = ({ languageTextMapping, className, Tag }) => {
  const [renderLanguageTextMapping, setRenderLanguageTextMapping] = useState({});

  const currentLanguageCode = useSelector((state) => state.multilanguage.currentLanguageCode);

  useEffect(() => {
    if (currentLanguageCode === "ko" && !languageTextMapping["ko"] && languageTextMapping["kr"]) {
      const newLanguageTextMapping = { ...languageTextMapping, ko: languageTextMapping["kr"] };
      setRenderLanguageTextMapping(newLanguageTextMapping);
      return;
    }
    setRenderLanguageTextMapping(languageTextMapping);
  }, [languageTextMapping]);

  const text = renderLanguageTextMapping[currentLanguageCode] || renderLanguageTextMapping["en"];

  if (Tag) {
    return <Tag className={className}>{text}</Tag>;
  }

  return <span className={className}>{text}</span>;
};

export default LanguageText;
